import React, { useState } from 'react'
import "./gallery.styles.scss";

import { IoMdClose } from "react-icons/io";

import Img1 from '../../assets/gallery/20230831_102517.jpg';
import Img2 from '../../assets/gallery/20231113_154222.jpg';
import Img3 from '../../assets/gallery/20240123_092548.jpg';
import Img4 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.48 (2).jpeg';
import Img5 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.48 (4).jpeg';
import Img6 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.49 (1).jpeg';
import Img7 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.49 (10).jpeg';
import Img8 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.49 (3).jpeg';
import Img9 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.49 (8).jpeg';
import Img10 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.49.jpeg';
import Img11 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.50 (10).jpeg';
import Img12 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.50 (11).jpeg';
import Img13 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.50 (2).jpeg';
import Img14 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.50 (4).jpeg';
import Img15 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.50.jpeg';
import Img16 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.51 (10).jpeg';
import Img17 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.51 (4).jpeg';
import Img18 from '../../assets/gallery/18.jpg';
import Img19 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.51 (8).jpeg';
import Img20 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.51.jpeg';
import Img21 from '../../assets/gallery/WhatsApp Image 2024-06-06 at 17.43.52 (2).jpeg';
import Img22 from '../../assets/gallery/gallery 6.jpeg';
import Img23 from '../../assets/gallery/23.jpg';
import Img24 from '../../assets/gallery/gallery 0.jpeg';
import Img25 from '../../assets/gallery/080824Gimg2.jpg';
import Img26 from '../../assets/gallery/080824Gimg3.jpg';
import Img27 from '../../assets/gallery/080824Gimg7.jpg';
import Img28 from '../../assets/gallery/080824Gimg5.jpg';
import Img29 from '../../assets/gallery/080824Gimg6.jpg';
import Img30 from '../../assets/gallery/080824Gimg8.jpg';
import Img31 from '../../assets/gallery/080824Gimg9.jpg';
import Img32 from '../../assets/gallery/080824Gimg10.jpg';
import Img33 from '../../assets/gallery/080824Gimg11.jpg';
import Img34 from '../../assets/gallery/gallery 1.jpg';
import Img35 from '../../assets/gallery/gallery 2.jpeg';
import Img36 from '../../assets/gallery/gallery 3.jpeg';
import Img37 from '../../assets/gallery/gallery 4.jpeg';
// import Img38 from '../../assets/gallery/gallery 5.jpeg';
import Img39 from '../../assets/gallery/gallery head img.jpeg';
import Img40 from '../../assets/gallery/preview-1.jpeg';
import Img41 from '../../assets/gallery/preview-11.jpeg';
import Img42 from '../../assets/gallery/preview-2.jpeg';
import Img43 from '../../assets/gallery/080824Himg4.jpg';
import Img44 from '../../assets/gallery/040826G1.jpeg';
import Img45 from '../../assets/gallery/040826G2.jpeg';
import Img46 from '../../assets/gallery/30-08-24G1.jpeg';
import Img47 from '../../assets/gallery/30-08-24G2.jpeg';
import Img48 from '../../assets/gallery/30-08-24G3.jpeg';
import Img49 from '../../assets/gallery/30-08-24G4.jpeg';
import Img50 from '../../assets/gallery/30-08-24G5.jpeg';
import Img51 from '../../assets/gallery/30-08-24G6.jpeg';



const Gallery = () => {

    let data = [
        {
            id:1,
            imgSrc: Img1,
            imgDis: 'Moveable hose rack',
            imgName: 'Hose rack',
        },
        {
            id:2,
            imgSrc: Img2,
            imgDis: 'Batonage',
            imgName: 'Batonage',
        },
        {
            id:3,
            imgSrc: Img3,
            imgDis: 'Fruit Fermentation Tanks',
            imgName: 'Fermentation Tanks',
        },
        {
            id:5,
            imgSrc: Img5,
            imgDis: '',
            imgName: '',
        },
        {
            id:6,
            imgSrc: Img6,
            imgDis: 'Pump over bucket',
            imgName: 'Pump over bucket',
        },
        {
            id:7,
            imgSrc: Img7,
            imgDis: 'Pipe merger',
            imgName: 'Pipe merger',
        },
        {
            id:8,
            imgSrc: Img8,
            imgDis: 'Ventilation pipe',
            imgName: 'Ventilation pipe',
        },
        {
            id:9,
            imgSrc: Img9,
            imgDis: 'Batonage',
            imgName: 'Batonage',
        },
        {
            id:10,
            imgSrc: Img10,
            imgDis: 'Catalogue',
            imgName: 'Catalogue',
        },
        {
            id:11,
            imgSrc: Img11,
            imgDis: 'Sight Glasses',
            imgName: 'Sight Glasses',
        },
        {
            id:12,
            imgSrc: Img12,
            imgDis: 'Catalogue',
            imgName: 'Catalogue',
        },
        {
            id:13,
            imgSrc: Img13,
            imgDis: 'Wine tank top hatch',
            imgName: 'Wine tank hatch',
        },
        {
            id:14,
            imgSrc: Img14,
            imgDis: 'Mash Cooler',
            imgName: 'Mash Cooler',
        },
        {
            id:15,
            imgSrc: Img15,
            imgDis: 'Hose valve attachment',
            imgName: 'Hose valve',
        },
        {
            id:16,
            imgSrc: Img16,
            imgDis: 'Custom Pipeing',
            imgName: 'Custom Pipeing',
        },
        {
            id:17,
            imgSrc: Img17,
            imgDis: 'Catalogue',
            imgName: 'Catalogue',
        },
        {
            id:18,
            imgSrc: Img18,
            imgDis: 'Punch down',
            imgName: 'Punch down',
        },
        {
            id:19,
            imgSrc: Img19,
            imgDis: 'Empty bucket rack',
            imgName: 'Empty bucket rack',
        },
        {
            id:48,
            imgSrc: Img48,
            imgDis: 'Sight Glass',
            imgName: 'Sight Glass',
        },
        {
            id:20,
            imgSrc: Img20,
            imgDis: 'Valve repair',
            imgName: 'Valve repair',
        },
        {
            id:26,
            imgSrc: Img26,
            imgDis: 'Pipeline Pump fittings',
            imgName: 'Pump fittings',
        },
        {
            id:21,
            imgSrc: Img21,
            imgDis: 'Movable step ladder',
            imgName: 'Movable step ladder',
        },
        {
            id:22,
            imgSrc: Img22,
            imgDis: 'Grape Destemmer',
            imgName: 'Grape Destemmer',
        },
        {
            id:23,
            imgSrc: Img23,
            imgDis: 'Punch down',
            imgName: 'Punch down',
        },
        {
            id:24,
            imgSrc: Img24,
            imgDis: 'Hose Managment',
            imgName: 'Hose Managment',
        },
        {
            id:25,
            imgSrc: Img25,
            imgDis: 'Heavy duty red wine sprayer',
            imgName:'Red wine sprayer'
        },
        {
            id:27,
            imgSrc: Img27,
            imgDis: 'Wine racks',
            imgName: 'Wine racks',
        },
        {
            id:47,
            imgSrc: Img47,
            imgDis: 'Sample Tap Key',
            imgName: 'Sample Tap Key',
        },
        {
            id:4,
            imgSrc: Img4,
            imgDis: 'Butterfly valves',
            imgName: 'Butterfly valves',
        },
        {
            id:28,
            imgSrc: Img28,
            imgDis: '',
        },
        {
            id:29,
            imgSrc: Img29,
            imgDis: '',
        },
        {
            id:30,
            imgSrc: Img30,
            imgDis: 'Custom Pipes',
            imgName: 'Custom Pipes',
        },
        {
            id:50,
            imgSrc: Img50,
            imgDis: 'C-Spanner',
            imgName: 'C-Spanner',
        },
        {
            id:31,
            imgSrc: Img31,
            imgDis: 'Punch down',
            imgName: 'Punch down',
        },
        {
            id:32,
            imgSrc: Img32,
            imgDis: 'Sink station',
            imgName: 'Sink station',
        },
        {
            id:33,
            imgSrc: Img33,
            imgDis: 'Grape Cusher',
            imgName: 'Grape Cusher',
        },
        {
            id:34,
            imgSrc: Img34,
            imgDis: 'Custom Pipeing',
            imgName: 'Custom Pipeing',
        },
        {
            id:35,
            imgSrc: Img35,
            imgDis: '',
        },
        {
            id:36,
            imgSrc: Img36,
            imgDis: 'Copper Fitting',
            imgName: 'Copper Fitting',
        },
        {
            id:37,
            imgSrc: Img37,
            imgDis: 'Custom Piping',
            imgName: 'Custom Piping',
        },
        // {
        //     id:38,
        //     imgSrc: Img38,
        //     imgDis: 'Custom Piping',
        //     imgName: 'Custom Piping',
        // },
        {
            id:49,
            imgSrc: Img49,
            imgDis: 'Camlock Fitting',
            imgName: 'Camlock Fitting',
        },
        {
            id:39,
            imgSrc: Img39,
            imgDis: 'Cat Walk',
            imgName: 'Cat Walk',
        },
        {
            id:40,
            imgSrc: Img40,
            imgDis: 'Red Wine propeller',
            imgName: 'Red Wine propeller',
        },
        {
            id:41,
            imgSrc: Img41,
            imgDis: 'Cat Walk',
            imgName: 'Cat Walk',
        },
        {
            id:42,
            imgSrc: Img42,
            imgDis: 'Tank Mixers',
            imgName: 'Tank Mixers',
        },
        {
            id:43,
            imgSrc: Img43,
            imgDis: 'Pump over bucket',
            imgName: 'Pump over bucket',
        },
        {
            id:44,
            imgSrc: Img44,
            imgDis: 'Wine Fermenters',
            imgName: 'Wine Fermenters',
        },
        {
            id:46,
            imgSrc: Img46,
            imgDis: 'Red Wine propeller',
            imgName: 'Red Wine propeller',
        },
        {
            id:51,
            imgSrc: Img51,
            imgDis: 'Sample Tap Key',
            imgName: 'Sample Tap Key',
        },
        {
            id:45,
            imgSrc: Img45,
            imgDis: '',
        },
        
    ]

    const [model ,setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');
    const [tempImgDis, setTempImgDis] = useState('');

    const getImg = (imgSrc,imgDis) => {
        setTempImgSrc(imgSrc);
        setModel(true);
        setTempImgDis(imgDis)
    }

  return (
    <>  
        <div className={model ? 'model open' : 'model'}>
            <h1 classname='pic-model-dis'>{tempImgDis}</h1>
            <img src={tempImgSrc}/>
            <IoMdClose onClick={() => setModel(false)}/>
        </div>  

        <div className="gallery">
            {
                data.map((item, index) => {
                    return (
                        <div className='pic-container'>
                            {/* <h1 className='pic-dis'>{item.imgName}</h1> */}
                            {/* <h1 className={model === false ? 'display-none' : 'pic-dis-model'}>test</h1> */}
                            {/* <div className='pics max-width' key={index} onClick={() => getImg(item.imgSrc, item.imgDis)}> */}
                            <div className='pics max-width' key={index} onClick={() => getImg(item.imgSrc)}>
                                <img src={item.imgSrc} alt={item.id} className='max-width pic'></img>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </>
  )
}

export default Gallery;
